import React from "react"
import Layout from "@components/layout"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

// Import SVG
import icon_tutorial from '@images/blog_menu_icon1.webp';
import icon_marketStrategy from '@images/blog_menu_icon2.webp';
import icon_moneyManagement from '@images/blog_menu_icon3.webp';
import icon_mental from '@images/blog_menu_icon4.webp';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// JSONファイル呼び出し
import blogText from "@content/locales/ja/blog-archive.json";

// コンポーネント呼び出し
import { BlogGraphqlArray } from "@components/graphql-data-blog.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定（Title,description以外）
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能

const BlogArchive = ({data, pageContext}) => {
  const indexUrl = '/';
  const typeUrl = 'blog/'
  const name = pageContext.name; // 該当カテゴリー名
  const slug = pageContext.slug; // 該当slug
  const myPageNum = pageContext.page;
  const wordSearchContent = WordSearch(indexUrl, typeUrl, slug).Blog; // ワード検索Blog
  const searchList = SearchCategList().Main; // カテゴリ検索リスト(components/search-categList.js)
  
  // Seo情報設定（Title,description以外）
  const pathName = 'tags';
  const seoTag = SeoContents({ Path:pathName, Title:name });
  
  // graphql-data-post.jsから記事データ呼び出し
  const setNum = 6; // 一覧の表示数
  const allPostData = BlogGraphqlArray(name, 'allmix');

  // 本文設定（各カテゴリ、blog-archive.jsonで設定）
  let commonTitle = blogText[ "common-title" ];
  let postTitle = blogText[ pageContext.slug + "-title" ];
  let postText1 = blogText[ pageContext.slug + "-txt1" ];

  // 記事一覧条件分岐
  let location = useLocation()
  let setArray, categoryVisual, pickupList, pagination, startNum, endNum;
  setArray = allPostData.checkCateg; // 該当カテゴリ配列情報

  // カテゴリ、タグアーカイブ一覧 ==================================================================================================
  if ( pageContext.type === 'category' || pageContext.type === 'tag' ) {
    
    // カテゴリビジュアル設定
    let setVisual = [icon_tutorial, icon_marketStrategy, icon_moneyManagement, icon_mental]; // 使用する画像をここで設定する
    let blogArray = BlogGraphqlArray('', 'header').mainCategUrl;
    let addCSS = "opacity-[50%]"; // 現在のカテゴリ以外の画像にかかるCSSを設定
    for (let i = 0; i < blogArray.length; i++){ // URL配列に画像情報とCSSを追加
      if ( blogArray[i][0] !== name ) {
        blogArray[i].push(setVisual[i], addCSS)
      } else {
        blogArray[i].push(setVisual[i], "")
      }
    }
    categoryVisual = blogArray.map((result) => { // 出力
      return  <>
                <a className={"block max-w-[220px] md:w-[22%] w-[46%] mb-5 bg-white p-10 rounded-xl " + result[3]} href={indexUrl + result[1] + "/"}>
                  <div >
                    <p><img alt="" src={result[2]}/></p>
                  <p className="text-center pt-5 text-[13px]">{result[0]}</p>
                  </div>
                  
                </a>
              </>
    });

    // 記事一覧生成
    let resultArray =[];
    startNum = (myPageNum - 1) * setNum; // 一覧の先頭番号
    endNum = myPageNum * setNum; // 一覧の最終番号
    endNum = (endNum > setArray.length) ? setArray.length : endNum; // 6個に足りていない場合の処理
    for (let i = startNum; i < endNum; i++){
      resultArray.push(setArray[i])
    }
    pickupList = resultArray.map((result) => {
      const len = 35; // title制限文字数
      const shortName = (result.Title.length > len)
      ? result.Title.substr(0, len) + ' ...'
      : result.Title;
  
      const lenEx = 60; // Description制限文字数
      const shortNameEx = (result.Description.length > lenEx)
      ? result.Description.substr(0, lenEx) + ' ...'
      : result.Description;
      return  <>
                <div className="md:w-[31.33333%] w-full  mb-10   max-w-[350px] border-[1px] rounded-xl mx-[1%]" key={result.Uri} >
                  <article className="post-list-item !m-0 h-[100%]"  itemScope itemType="http://schema.org/Article">
                    <a href={indexUrl + typeUrl + result.Slug + "/"} itemProp="url" className="Campaign w-[100%] h-[100%]">
                      <div className="w-full h-[85%] aspect-[10/7.4]">
                        {/* 画像部分 */}
                        <GatsbyImage className="block w-full h-full rounded-blog overflow-hidden" image={result['Image']}
                          alt={result.AltText ? result.AltText : ""} as={`figure`} style={{ aspectRatio: "25/14" , height: '100%' }}
                        />
                      </div>
                      {/* タイトル部分 */}
                      <div className="w-full text-left h-[20%] px-3 py-2">
                        <p className="CampP md:text-[15px] text-[15px]  mb-4 py-2 text-left " >{shortName}</p>
                        <p className="text-[8px]  text-left pb-2" >{shortNameEx}</p>
                        {/* <p className="text-[8px]  text-left pb-2" ><span>作成日：〇〇〇〇〇〇〇〇</span> <span className="pl-2">更新日：〇〇〇〇〇〇〇〇</span></p> */}
                      </div>
                    </a>
                  </article>
                </div>
              </> ;
    });
    // ページネーション設定
    let previous = myPageNum - 1;
    let previousUrl = (previous === 1) ? indexUrl + typeUrl + pageContext.slug + "/" : ((previous < 1) ? "" : indexUrl + typeUrl + pageContext.slug + "/" + previous + "/");
    // let current = myPageNum;
    // let currentUrl = (current === 1) ? indexUrl + typeUrl + pageContext.slug + "/" : ((current < 1) ? "" : indexUrl + typeUrl + pageContext.slug + "/" + current + "/");
    let next = myPageNum + 1;
    let nextUrl = (next === 1) ? indexUrl + typeUrl + pageContext.slug + "/" : ((next > pageContext.allPages) ? "" : indexUrl + typeUrl + pageContext.slug + "/" + next + "/");
    pagination = 
    <>
      <div className="flex py-10 text-[11px] text-sm border-t-[#D0D0D0] border-t-[0.6px] border-solid">
        <div className="text-center w-1/2">
          {previousUrl && <a href={previousUrl}>＜ PREV</a>}
        </div>
        <div className="text-center w-1/2">
          {nextUrl && <a href={nextUrl}>NEXT ＞</a>}
        </div> 
      </div>
    </>



  // 検索結果一覧 ==================================================================================================
  } else if ( pageContext.type === 'search' ) {
    categoryVisual = <></>
    let urlItem = location.href;
    let item = '#';
    let word;

    let index1;
    // let status;
    if ( urlItem !== undefined ){
      index1 = urlItem.indexOf(item);
    } else {
      index1 = -1;
    }

    if (index1 !== -1 ) { // 検索結果ありの場合
      let check = decodeURI(urlItem.substring(index1 + 1));
      word = check; // 検索ワードセット
      let searchWord;
      let resultArray = [];
      for ( let i = 0; i < setArray.length; i++) {
          searchWord = setArray[i].Title + setArray[i].Content;
          if ( searchWord.toLowerCase().indexOf(check.toLowerCase()) !== -1) {
            resultArray.push(setArray[i])
          } 
        }
      setArray = resultArray;
      // status = 'loadcomp'
      postTitle = "「" + word + "」の検索結果：" + setArray.length + "件"; // 表示名作成
    } else if ( index1 === -1 ) { // 検索結果がない場合
      postTitle = `検索中`
      // status = 'loading'
      setArray = [];
    }
    // 記事一覧生成
    pickupList = setArray.map((result) => {
      return  <>
                <div className="md:w-1/3 md:aspect-square" key={result.Uri} style={{marginBottom:15 ,padding:10}}>
                  <article className="post-list-item !m-0 " style={{ height: '100%' }} itemScope itemType="http://schema.org/Article">
                    <a href={indexUrl + typeUrl + result.Slug + "/"} itemProp="url" className="Campaign w-[100%]">
                      <div className="w-full" style={{ height: '55%' }}>
                        {/* 画像部分 */}
                        <GatsbyImage className="block w-full h-full rounded-blog overflow-hidden" image={result['Image']}
                          alt={result.AltText ? result.AltText : ""} as={`figure`} style={{ aspectRatio: "25/14" , height: '100%' }}
                        />
                      </div>
                      {/* タイトル部分 */}
                      <div className="bg-white w-full text-left" >
                        <p className="CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{result.Title}</p>
                        <p className="text-[11px] text-left  md:text-[10px]  pb-4" >{parse(result.Excerpt)}</p>
                      </div>
                    </a>
                  </article>
                </div>
              </>;
    });
    // ページネーション設定
    pagination = <></>
  }

  const fixedItem = FixedFoot( 'nolmal', '' ); // 固定フッター

    return (
      <>
        <Layout>
          {seoTag}
          <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
            <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
              <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
                <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{ commonTitle }</h2>
                <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">プロップファームを利用する際に必要になる<br></br>注意点や基本的知識などを掲載しています。</h3>
              </div>
            </div>

            {/* カテゴリービジュアル */}
            <div className="mx-auto flex max-w-xl flex-wrap  md:max-w-5xl justify-around justify-between mt-7 px-5 md:px-0">
              {categoryVisual}
            </div>
          
          </div>
          
          {/* カテゴリ本文 */}
          <div className="md:max-w-5xl max-w-xl mx-auto pt-5 px-5 pb-5"> 
            <div>
              <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6] mt-[70px] ">{ postTitle }</h3>
              <p className="mb-[30px] max-w-[300px] md:max-w-[960px] overflow-hidden">{postText1}</p>
            </div>
          </div>

          {/* 記事一覧 */}
          <div className="mx-auto flex max-w-xl flex-wrap items-center md:max-w-5xl md:justify-start justify-center px-5">
            {pickupList}
          </div>

          {/* ページネーション */}
          {pagination}

          {/* 検索窓 */}
          <div className="bg-[#F6F6F6] pt-[30px]">
            <div className="max-w-xl mx-auto md:max-w-5xl px-5 ">
              <p className="font-bold mb-[30px]">記事検索</p>
              <div className="md:flex pb-[45px]">
                <div className="md:w-[50%]">{wordSearchContent}</div>
                <div className="md:w-[50%]">{searchList}</div>
              </div>
            </div>
          </div>	

          {/* 固定フッター */}
          {fixedItem}
        </Layout>
      </>
    )
  }
export default BlogArchive